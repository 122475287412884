import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    words: [],
    status: 'empty',
};

export const dictionarySlice = createSlice({
    name: 'dictionary',
    initialState,
    reducers: {
        load: (state, action) => {
            state.status = 'loaded';
            state.words = action.payload;
        }
    }
});


export const {load} = dictionarySlice.actions;


export default dictionarySlice.reducer;
