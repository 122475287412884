import React from 'react';

import { useSelector, useDispatch} from 'react-redux'
import { enableLevel, enableMode } from "../store/settings/settingsSlice";

import { HUMAN_READABLE_MODES } from "../constants/GameSettings";

const getActiveCount = (target) => Object.keys(target).filter(key => target[key]).length;
const ucFirst = (word) => word.charAt(0).toUpperCase() + word.slice(1);

function LevelCheckboxes({ levels }) {
    const dispatch = useDispatch();

    const rows = []
    const keys = Object.keys(levels)

    keys.forEach((key) => {
        rows.push(<span  key={key} >
            <input type="checkbox" name={key} value={levels[key]} defaultChecked={levels[key]} disabled={levels[key] && getActiveCount(levels) <= 1}
                   onChange={(event) => dispatch(enableLevel({ level: key, enabled: event.target.checked }))} /><span> {ucFirst(key)}</span> </span>);
    });

    return (<> { rows} </> );
}

function ModesCheckboxes({ modes })
{
    const dispatch = useDispatch();
    const rows = []

    Object.keys(modes).forEach((key) => {

        rows.push(<span  key={key} >
                <input type="checkbox" name={key} value={modes[key]} defaultChecked={modes[key]} disabled={modes[key] && getActiveCount(modes) <= 1}
                       onChange={(event) => dispatch(enableMode({ mode: key, enabled: event.target.checked }))} /><span> {HUMAN_READABLE_MODES[key]}</span> </span>);
    });

    return (<> { rows} </> );
}

function Settings() {
    const settings = useSelector((state) => state.settings)

    return (
        <div><h2>Settings</h2>
            <h4>Level</h4>
            <LevelCheckboxes levels={settings.levels} /><br />
            <h4>Modes</h4>
            <ModesCheckboxes modes={settings.modes} />
        </div>
    );
}

export default Settings;
