export const NUMBER_OF_QUESTIONS = 20;

export const VERB_TYPES = ['mag', 'ma', 'um', 'in', 'i'];

export const CONJUGATE_TYPES = [ "future", "present", "past", "infinitive"];

export const QUESTION_MODES = {
    MODE_MEANING: 'meaning',
    MODE_ROOT: 'root',
    MODE_CONJUGATE: 'conjugate'
}

export const HUMAN_READABLE_MODES = {
    conjugate: 'Conjugate',
    meaning: 'Verb meaning',
    root: 'Root word',
}
