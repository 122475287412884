import React from 'react';

import {useState, useEffect} from "react";
import styles from './game.module.css';

import {HUMAN_READABLE_MODES} from "../constants/GameSettings";

function QuestionScreen({options, answerQuestion}) {
    const rows = [];
    options.forEach((option, index) => {
        rows.push(<div key={index} className="uk-width-1-2 uk-padding-small">
            <button className="uk-button uk-button-primary uk-align-center"
                    onClick={() => answerQuestion(option)}>{index + 1}: {option} </button>
        </div>)
    });

    return (<div className="uk-grid  uk-child-width-1-2">
            {rows}
        </div>
    )
}

function ResultScreen({answer, userAnswer, onFinish, loose}) {

    const answerEvaluator = (loose, answer, userAnswer) => {
        const parts = answer.split('/');
        if (!loose || parts.length === 1) {
            return userAnswer.includes(answer);
        }

        return !!parts.find(f => f.includes(userAnswer))
    }

    const isCorrect = answerEvaluator(loose, answer, userAnswer);
    const resultIcon = isCorrect ? './checkmark.svg' : './error.svg';
    const imgInsert = (<img alt="success or fail" src={resultIcon} className={styles.answerIcon}
                            onClick={() => onFinish(isCorrect)}/>);
    const buttonInsert = (<button className="uk-button uk-button-default uk-align-center"

                                  onClick={() => onFinish(isCorrect)}>Next</button>)

    if (isCorrect) {
        return (<div onKeyDown={() => onFinish(isCorrect)}>
            {imgInsert}
            <span>Good job: the right answer is <b>{answer}</b></span>
            <br/>
            {buttonInsert}
        </div>)

    }

    return (<div onKeyDown={() => onFinish(isCorrect)}>
        {imgInsert}
        <span>unfortunately  the right answer is <strong>{answer}</strong><br/>
            You answered: <strong>{userAnswer}</strong></span>

        <br/>
        {buttonInsert}
    </div>)
}

function TypedQuestionScreen({answerQuestion}) {

    const [userAnswer, userAnswerChanged] = useState('');

    const handleUserAnswer = (data) => {
        userAnswerChanged(data.target.value);
    }

    const triggerAnswerQuestion = () => {

        const cleanAnswer = userAnswer.trim().toLowerCase();

        answerQuestion(cleanAnswer)
    }

    const handleEnterPress = (event) => {
        if (event.key === 'Enter') {
            triggerAnswerQuestion();
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleEnterPress);

        return () => {
            window.removeEventListener('keydown', handleEnterPress);
        };
    });


    return (<div className="uk-grid  uk-child-width-1-2">
            <div>
                <input type="text" className="uk-input" onChange={handleUserAnswer}/>
            </div>
            <div>
                <button className="uk-button uk-button-primary uk-form-width-small"
                        onClick={triggerAnswerQuestion}>OK
                </button>
            </div>
        </div>
    );

}

function Question({content, onFinish}) {
    const [answered, markAnswered] = useState(false);
    const [userAnswer, setUserAnswer] = useState(null);

    const isTypedQuestion = (Math.round(Math.random(2) * 2) === 0)
    const [isLoose] = useState(isTypedQuestion);

    const answerQuestion = (answer) => {
        setUserAnswer(answer);
        markAnswered(true);
    }

    const handleKeyPress = (event) => {
        if (answered) {
            onFinish(userAnswer === content.answer)
        } else {
            const keyNumber = parseInt(event.key);
            if (!isNaN(keyNumber) && keyNumber > 0 && keyNumber < 5) {
                const answer = content.options[keyNumber - 1];
                answerQuestion(answer);
            }
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    });

    const question = content.explain ?
        (content.explain) :
        (HUMAN_READABLE_MODES[content.mode] + ' of: <strong>' + content.question + '</strong> ?')


    const questionScreen = isTypedQuestion ?
        (<TypedQuestionScreen answerQuestion={answerQuestion}/>) :
        (<QuestionScreen options={content.options} answerQuestion={answerQuestion}/>);

    return (
        <div>
            <h4 dangerouslySetInnerHTML={{__html: question}}></h4>
            {answered
                ?
                (<ResultScreen answer={content.answer} loose={isLoose} userAnswer={userAnswer}
                               onFinish={onFinish}/>) :
                questionScreen
            }
        </div>
    )
}

export default Question;
