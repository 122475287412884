import { configureStore } from '@reduxjs/toolkit';

import dictionaryReducer from './dictionary/dictionarySlice'
import settingsReducer from "./settings/settingsSlice";
import historyReducer from "./history/historySlice";

export const store = configureStore({
  reducer: {

    dictionary: dictionaryReducer,
    settings:  settingsReducer,
    history: historyReducer,
  },
});
