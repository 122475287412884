const defaultState = {
    gamesPlayed: 0
};

export const loadInitialState = () => {

    let initialState = defaultState;
    const savedState = localStorage.getItem('history');
    if(savedState) {
        const extractedState = JSON.parse(savedState);
        initialState = {...initialState, ...extractedState};
    }

    return initialState;
}

export const saveCurrentState = (state) => {
    localStorage.setItem('history', JSON.stringify(state));
}
