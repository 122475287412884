import React, {useEffect, useState} from 'react';
import './App.css';

import {load} from "./store/dictionary/dictionarySlice";

import Settings from "./components/Settings";
import Game from "./components/Game";
import {useDispatch} from "react-redux";
import {incrementGames} from "./store/history/historySlice";

function buildScreen(screen) {
    switch (screen) {
        case 'settings':
            return (<Settings/>);
        case 'game':
            return (<Game/>);
        default:
            return (<div>Start a new game</div>);
    }
}

function buildButtons(started, screen, startGame, endGame, openSettings, loaded)
{
    if (started) {
        return (  <button className="uk-button uk-button-danger uk-align-center " disabled={! loaded}
                          onClick={() => endGame()}>End game</button>);
    }

    const toReturn = [];
    toReturn.push((<button key="startbutton" className="uk-button uk-button-default uk-align-center " disabled={! loaded} onClick={() => startGame()}>Start game</button>) )

    if (screen !== 'settings') {
        toReturn.push((<button key="settingsbutton" className="uk-button uk-button-default uk-align-center " disabled={! loaded} onClick={() => openSettings(true)}>Open settings</button>) )
    }
    else {
        toReturn.push((
            <button   key="settingsbutton" className="uk-button uk-button-default uk-align-center " disabled={! loaded} onClick={() => openSettings(false)}>Close
                settings</button>))
    }

    return toReturn;
}

function App()
{
    const dispatch = useDispatch();
    const [started, setStarted] = useState(false);
    const [screen, setScreen] = useState('start');

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        (async () => {
            const reqOptions =  {headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }};

            const response = await fetch("https://pandiwa.emtay.company/api/pandiwas?pagination=false&" + new Date().getTime(), reqOptions);
            const dictionary = await response.json();

            dispatch(load(dictionary));
            setLoaded(true);
        })();
    }, [dispatch, setLoaded]);

    const startGame = () =>  {
        dispatch(incrementGames());

        setStarted(true);
        setScreen('game');
    }

    const endGame = () =>  {
        setStarted(false);
        setScreen('start');
    }

    const openSettings = (isOpen) => {
        const pickedScreen = isOpen ? 'settings' : 'start';
        setScreen(pickedScreen);
    }


    return (
        <div className="App uk-padding-small">
            <div
                className="uk-card uk-card-body uk-card-default uk-align-center uk-width-1-1@s uk-width-3-4@m uk-width-2-3@l uk-width-2-3@xl ">

                <div className="uk-card-title">
                    <h3 className="uk-margin-remove-bottom">🇵🇭 Pandiwa - Ang laro 🕹</h3>
                    <h5 className="uk-margin-remove-top">🧑‍🎓 <i>Kung kailangan mong magsanay ng mga pandiwa.</i> 😄</h5>
                </div>
                <div className="uk-grid uk-text-center" data-uk-grid>
                    <div className="uk-tile uk-tile-default uk-width-1-1@s uk-width-2-3@m ">
                        {buildScreen(screen)}
                    </div>
                    <div className="uk-tile uk-tile-default uk-tile-small uk-tile-primary uk-width-1-1@s uk-width-1-3@m " style={{minHeight: '166px'}}>
                        <div className="uk-position-center ">
                            { buildButtons(started, screen, startGame, endGame, openSettings, loaded) }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
