import { createSlice } from '@reduxjs/toolkit';
import { loadInitialState, saveCurrentState } from "./settingsState";

const initialState = loadInitialState();

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        enableLevel: (state, action) => {
            state.levels[action.payload.level] = !!action.payload.enabled;
            saveCurrentState(state);
        },
        enableMode: (state, action) => {
            state.modes[action.payload.mode] = !!action.payload.enabled;
            saveCurrentState(state);
        }

    }
});

export const { enableLevel, enableMode } = settingsSlice.actions;

export default settingsSlice.reducer;
