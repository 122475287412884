import {store} from '../store/store';

import {CONJUGATE_TYPES, QUESTION_MODES} from "../constants/GameSettings";

const shuffledArr = array => array.sort(() => 0.5 - Math.random());

class QuestionGenerator {
    constructor() {
        this.activeWords = this.loadActiveWords();
        this.modes = this.loadModes();
    }

    generateQuestion() {
        const pickedIndex = this.getRandomItem([]);
        const mode = this.pickMode();

        const otherIndices = [];
        for (let i = 0; i < 3; ++i) {
            let otherIndex = this.getRandomItem(otherIndices + [pickedIndex]);
            otherIndices.push(otherIndex);
        }

        const question = this.getQuestionWord(pickedIndex, mode);
        const answer = this.getAnswer(pickedIndex, mode, question);

        return {
            mode: mode,
            question: question,
            answer: answer,
            explain: this.getQuestionExplanation(pickedIndex, mode, question, answer),

            options: this.getOptions(pickedIndex, answer, mode),
        }
    }

    getOptions(pickedIndex, answer, mode) {
        switch (mode) {
            case QUESTION_MODES.MODE_CONJUGATE:
                return this.getConjugateOptions(pickedIndex, answer);
            case QUESTION_MODES.MODE_MEANING:
            case QUESTION_MODES.MODE_ROOT:
                return this.getOtherOptions(pickedIndex, answer, mode);
            default:
                throw Error('unexpected mode' + mode);
        }
    }

    getConjugateOptions(pickedIndex, answer) {
        const options = [answer];
        for (let i = 0; i < 3; ++i) {
            let field = CONJUGATE_TYPES[i];
            let wrongAnswer = this.getAnswer(pickedIndex, 'conjugate', field);

            if (wrongAnswer !== answer) {
                options.push(wrongAnswer);
            }
        }

        return shuffledArr(options);
    }

    getOtherOptions(pickedIndex, answer, mode) {
        const otherIndices = [];
        for (let i = 0; i < 3; ++i) {
            let otherIndex = this.getRandomItem(otherIndices + [pickedIndex]);
            otherIndices.push(otherIndex);
        }

        const toReturn = [answer];
        otherIndices.forEach((index) => {

            const wrongAnswer = this.getAnswer(index, mode);
            toReturn.push(wrongAnswer);
        })

        return shuffledArr(toReturn);
    }

    pickMode() {
        return this.modes[Math.floor(Math.random() * this.modes.length)];
    }

    getQuestionWord(index, mode = 'meaning') {
        const word = this.activeWords[index];
        switch (mode) {
            case QUESTION_MODES.MODE_CONJUGATE:
                return CONJUGATE_TYPES[Math.floor(Math.random() * CONJUGATE_TYPES.length)]
            case QUESTION_MODES.MODE_MEANING:
                return word.root;
            case QUESTION_MODES.MODE_ROOT:
                return word.meaning;
            default:
                throw Error('unexpected mode' + mode);
        }
    }

    getAnswer(pickedIndex, mode = 'meaning', question = '') {
        const word = this.activeWords[pickedIndex];

        switch (mode) {
            case QUESTION_MODES.MODE_MEANING:
                return word.meaning;
            case QUESTION_MODES.MODE_ROOT:
                return word.root;
            case QUESTION_MODES.MODE_CONJUGATE:
                return word[question];
            default:
                throw Error('unexpected mode' + mode);
        }

    }

    getRandomItem(excludeItems = []) {
        const indices = Object.keys(this.activeWords).map((index) => parseInt(index));

        const available = indices.filter(index => !excludeItems.includes(index));

        return available[Math.floor(Math.random() * available.length)];
    }


    loadActiveWords() {
        const state = store.getState();

        const dictionary = state.dictionary.words;
        const levels = state.settings.levels;

        return dictionary.filter((entry) => {
            return (entry.category === 'basic' && levels.basic)
                || (entry.category === 'moderate' && levels.moderate)
                || (entry.category === 'advanced' && levels.advanced)
        })
    }

    loadModes() {
        const state = store.getState();
        const modeSettings = state.settings.modes;

        const modes = [];
        Object.keys(modeSettings).forEach((mode) => {
            if (modeSettings[mode]) {
                modes.push(mode);
            }

        })

        return modes;
    }

    getQuestionExplanation(pickedIndex, mode, question, answer) {
        if (mode !== QUESTION_MODES.MODE_CONJUGATE)
        {
            return null;
        }

        const word = this.activeWords[pickedIndex];

        return 'Conjugate <strong>' + word.root + '</strong> ('+ word.type + ' verb) into the ' + question + ' form';
    }
}


export default QuestionGenerator;
