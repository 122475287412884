import { createSlice } from '@reduxjs/toolkit';
import { loadInitialState, saveCurrentState } from "./historyState";

const initialState = loadInitialState();

export const historySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        incrementGames: (state, action) => {
            state.gamesPlayed = state.gamesPlayed + 1;
            saveCurrentState(state);
        },
        enableMode: (state, action) => {
            state.modes[action.payload.mode] = !!action.payload.enabled;
            saveCurrentState(state);
        }

    }
});

export const { incrementGames } = historySlice.actions;

export default historySlice.reducer;
