import React, {useState} from 'react';

import {NUMBER_OF_QUESTIONS} from "../constants/GameSettings";

import QuestionGenerator from "../services/QuestionGenerator";
import Question from "./Question";
import {useSelector} from "react-redux";

function FinishScreen({wrong, correct, maxQuestions})
{
    const percentage = Math.round((correct / maxQuestions).toFixed(2) * 100);

    return (
        <div><h4>Quiz finished</h4>
            Good answers: {correct}<br/>
            Wrong answers: {wrong}<br/>
            <br/>
            Your score: {percentage}%
        </div>)
}

function makeQuestion(currentQuestion, generator, advanceQuiz) {
    return (<Question key={currentQuestion} content={generator.generateQuestion()}
                      onFinish={(isCorrect) => advanceQuiz(isCorrect)}/>)
}

function Game()
{
    const maxQuestions = NUMBER_OF_QUESTIONS;
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [correct, setCorrect] = useState(0);
    const [wrong, setWrong] = useState(0);
    const [finished, setFinished] = useState(false);

    const quizNumber = useSelector((state) => state.history.gamesPlayed);

    const questionGenerator = new QuestionGenerator();

    const advanceQuiz = (isCorrect) => {
        if (isCorrect) {
            setCorrect(correct + 1);
        } else {
            setWrong(wrong + 1)
        }

        if (currentQuestion + 1 < maxQuestions) {
            setCurrentQuestion(currentQuestion + 1);
        } else {
            setFinished(true);
        }
    }

    return (
        <div>
            <div>
                <div className="uk-position-top-left uk-padding-small"><h4>Playing quiz { quizNumber }</h4></div>
                <div
                    className="uk-position-top-right uk-padding-small">Question {currentQuestion + 1} / {maxQuestions}</div>
                {finished ? (<FinishScreen correct={correct} wrong={wrong} maxQuestions={maxQuestions} />) :
                    makeQuestion(currentQuestion, questionGenerator, advanceQuiz)}
            </div>

        </div>
    )
}

export default Game;
