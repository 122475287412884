const defaultState = {
    levels: {
        basic: true,
        moderate: true,
        advanced: false,
    },
    modes: {
        meaning: true,
        root: true,
        conjugate: true,
    },
    version: 2
};

export const loadInitialState = () => {

    let initialState = defaultState;
    const savedState = localStorage.getItem('settings');
    if(savedState) {
        const extractedState = JSON.parse(savedState);
        if (extractedState.version && extractedState.version === defaultState.version) {
            initialState = {...initialState, ...extractedState};
        }


    }

    return initialState;
}

export const saveCurrentState = (state) => {
    localStorage.setItem('settings', JSON.stringify(state));
}



